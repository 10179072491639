import React, { useState } from 'react';

const Branch = ({ href, imageSrc, overlayImageSrc, altText, overlayAltText }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="branch-container">
      <a href={href} className="branch">
        <div
          className="img-container"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img src={imageSrc} alt={altText} className="branch-image" />
          {isHovered && (
            <div className="overlay">
              <img src={overlayImageSrc} alt={overlayAltText} className="overlay-image" />
            </div>
          )}
        </div>
      </a>
    </div>
  );
};

export default Branch;